











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CircularProgressLoader extends Vue {
  @Prop({ type: Number, required: false, default: 60 }) size!: number;
  @Prop({
    type: Object,
    required: false,
    default: () => ({
      height: "90vh"
    })
  })
  styles!: object;
  @Prop({ type: Boolean, default: false }) center!: boolean;
}
